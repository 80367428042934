import { addBreadcrumb, captureException } from '@sentry/remix'
import type { CSSProperties } from 'react'

import { webviewRules } from '~/constants/common'

const webviewRegExp = new RegExp('(' + webviewRules.join('|') + ')', 'ig')

export const isWebview = (userAgent?: string | null) => {
  try {
    return !!userAgent?.match(webviewRegExp)
  } catch (e) {
    const err = e instanceof Error
    addBreadcrumb({
      message: 'utils > isWebview',
      level: 'error',
      data: {
        userAgent,
      },
    })
    captureException(err)

    return false
  }
}

export const makeCSSVariables = (variables: Record<string, any>) =>
  Object.entries(variables).reduce(
    (acc, [k, v]) => {
      acc[`--${k}`] = v
      return acc
    },
    {} as Record<string, any>,
  ) as CSSProperties
